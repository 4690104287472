<template>
    <div v-click-outside="hide" class="attempt-result-widget">
        <div @click="isOpen = !isOpen" :class="{ disabled: loading }" class="attempt-result-widget-handler">
            <div
                :class="{
                    'attempt-result-widget-handler-rect_message': count > 0
                }"
                :data-message-count="count"
                class="attempt-result-widget-handler-rect"
            >
                <Message2Icon />
            </div>
        </div>
        <transition name="fade-up">
            <div v-if="isOpen" class="attempt-result-widget-modal" :style="getModalPosition()">
                <CloseButtonSm @close="hide" />
                <template v-if="correctAnswers && correctAnswers.length">
                    <default-title class="mb-10" :size="16" :line-height="21" :weight="500">
                        {{ t("course.practice.correct_answer") }}:
                    </default-title>
                    <default-description class="mb-20">
                        {{ correctAnswers.join(", ") }}
                    </default-description>
                </template>
                <template v-if="commentableId">
                    <CommentsWidget
                        @add="count++"
                        @close="hide"
                        public
                        :attempt_id="attemptId"
                        :commentable_id="commentableId"
                        :commentable_type="commentableType"
                    />
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import Message2Icon from "@icons/Message2Icon"
import ClickOutside from "vue-click-outside"
import CloseButtonSm from "../../../Buttons/CloseButtonSm"
import CommentsWidget from "./AttemptCommentsWidget.vue"
import DefaultDescription from "../../../Typography/DefaultDescription"
import DefaultTitle from "../../../Typography/DefaultTitle"

export default {
    name: "AttemptResultStudentWidget",
    components: { DefaultDescription, Message2Icon, CloseButtonSm, CommentsWidget, DefaultTitle },
    props: {
        commentableId: {
            type: Number,
            default: 0
        },
        attemptId: {
            type: Number,
            default: 0
        },
        correctAnswers: {
            type: [Array, Object],
            default: null
        },
        commentsCount: {
            type: Number,
            default: 0
        },
        commentableType: {
            type: String,
            default: "question_id"
        }
    },
    directives: {
        ClickOutside
    },
    data() {
        return {
            isOpen: false,
            loading: false,
            count: this.commentsCount
        }
    },
    methods: {
        hide() {
            this.isOpen = false
        },
        getModalPosition() {
            return {
                top: "-5px",
                left: "-5px"
            }
        }
    }
}
</script>

<style lang="sass">
@import "attempt-result"
</style>
