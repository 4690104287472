<template>
    <div :style="questionStyle()" class="practice-question">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <FilesNewView v-if="question.files && question.files.length" :files="question.files" />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers"
            :class="{ 'practice-question__answers_disabled': disabledByTimer() }"
        >
            <div
                @click="setCorrect(answer)"
                :class="{ 'pointer-events-none': readonly }"
                class="practice-question__answer text-answer cursor-pointer"
                v-for="(answer, index) in question.answers"
                :key="index"
            >
                <flex-container
                    :style="{
                        opacity: isAnswered(answer) || checkable ? 1 : 0.4
                    }"
                >
                    <PracticeRadio
                        :class="{
                            'with-red':
                                (!interactive && typeShow() === 'full_information' && isAnswered(answer)) ||
                                (!interactive && typeShow() === 'only_correct_or_not' && isAnswered(answer)),
                            'with-black':
                                !interactive &&
                                (typeShow() === 'simple_answer' || typeShow() === 'only_correct_or_not_question')
                        }"
                        :active="getIsActive(answer)"
                    />
                    <div
                        :class="{
                            'practice-question__text-area-container': isCustomAnswer(answer) && answer.is_correct
                        }"
                    >
                        <template v-if="interactive">
                            <PracticeQuestionDescription
                                class="cursor-pointer"
                                :description="getTextWithCustom(answer)"
                            />

                            <textarea
                                @click.stop
                                ref="area"
                                class="practice-question__text-area"
                                v-if="isCustomAnswer(answer) && answer.is_correct"
                                @input="$emit('callout')"
                                v-model="answer.text_answer"
                                :placeholder="inlineText(answer.text)"
                                cols="60"
                                rows="7"
                            ></textarea>
                        </template>
                        <PracticeQuestionDescription
                            class="cursor-pointer"
                            v-else
                            :description="getTextWithCustom(answer)"
                        />
                        <img
                            :style="getImageStyle(answer)"
                            v-if="answer.image"
                            :src="answer.image.url || answer.image"
                            alt="image"
                            class="mt-10"
                        />
                    </div>
                </flex-container>
            </div>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :correct-answers="
                !interactive && typeShow() === 'full_information' && isIncorrect && isIncorrect() && getCorrectAnswers()
            "
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer :question="question" :quiz="quiz" v-if="interactive" v-on="$listeners" />
    </div>
</template>

<script>
import PracticeCorrectStatusAlert from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import FlexContainer from "../../Containers/FlexContainer"
import FilesNewView from "../../Files/FilesNewView"

import PracticeRadio from "../common/inputs/PracticeRadio"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"

import PracticeMixin from "./_mixin"

export default {
    mixins: [PracticeMixin],
    components: {
        PracticeCorrectStatusAlert,
        PracticeQuestionInfo,
        PracticeActionsContainer,
        FilesNewView,
        PracticeQuestionDefaultOptions,
        PracticeRadio,
        FlexContainer
    },
    name: "PracticeSingleAnswer",
    methods: {
        getIsActive(answer) {
            if (!this.interactive) {
                if (this.typeShow() === "simple_answer" || this.typeShow() === "only_correct_or_not_question") {
                    return !!this.isAnswered(answer)
                }
                if (this.typeShow() === "only_correct_or_not" || this.typeShow() === "full_information") {
                    return this.isAnswered(answer)?.is_correct
                }
            }
            return !!answer.is_correct
        },
        setCorrect(answer) {
            if (this.disabledByTimer() || !this.interactive) {
                if (!this.checkable) {
                    return
                } else {
                    this.$emit("callout")
                }
            }

            this.$nextTick(() => {
                for (const answer of this.question.answers) {
                    answer.is_correct = false
                }

                const issuedAnswer = this.question.answers.find(a => a.id === answer.id)

                issuedAnswer.is_correct = true

                if (this.isCustomAnswer(answer)) {
                    setTimeout(() => {
                        const area = this.$refs.area

                        if (area && area.length) {
                            area[0].focus()
                        }
                    })
                }

                this.$forceUpdate()
                this.$emit("callout")
            })
        }
    }
}
</script>
