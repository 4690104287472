<template>
    <div class="practice-comment">
        <p>
            <b
                >{{ t("course.practice.comment") }}
                <template v-if="attempt.checking"> {{ attempt.checking.name }}</template> </b
            >, <template v-if="attempt.checked_at"> {{ attempt.checked_at | formatDateWithTimeNoSeconds2 }}</template>
            <br />
        </p>
        <div class="text-view" v-html="attempt.comment"></div>

        <FilesNewView
            class="mt-10"
            v-if="attempt.comment_files && attempt.comment_files.length"
            :files="attempt.comment_files"
        />
    </div>
</template>

<script>
import moment from "moment"

import "moment/dist/locale/ru"
import "moment/dist/locale/uk"
import "moment/dist/locale/it"
import "moment/dist/locale/es"
import "moment/dist/locale/de"
import "moment/dist/locale/pl"
import FilesNewView from "@components/Files/FilesNewView.vue"

moment.locale(window.app_current_locale)

export default {
    components: { FilesNewView },
    props: {
        attempt: {
            type: Object,
            default: () => {}
        }
    },
    filters: {
        formatDateWithTimeNoSeconds2(value) {
            return moment(value).format("DD MMMM YYYY, HH:mm")
        }
    },
    name: "PracticeComment"
}
</script>
<style lang="sass" scoped>
.practice-comment
    font-size: 12px
    line-height: 15px
    margin-top: 5px
    b
        font-weight: 600
        color: #151515
    i
        color: #52565C
</style>
