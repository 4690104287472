<template>
    <div class="youtube-video-player">
        <YouTubeVideoPlayerContent
            @timeupdate="$emit('timeupdate', $event)"
            @play="$emit('play', $event)"
            @pause="$emit('pause', $event)"
            @ended="$emit('ended', $event)"
            @showDefault="$emit('showDefault')"
            :style="{ paddingBottom: padding }"
            v-if="!didUpdate"
            :info-unit-id="infoUnitId"
            :is-vertical="isVertical"
            :is-show-preview-on-pause="isShowPreviewOnPause"
            :link="link"
            :host="host"
            :preview="preview"
        />
    </div>
</template>

<script>
import YouTubeVideoPlayerContent from "@components/Players/new/YoutubeVideoPlayerContent.vue"

export default {
    components: { YouTubeVideoPlayerContent },
    props: {
        isShowPreviewOnPause: {
            type: Boolean,
            default: false,
            required: false
        },
        isVertical: {
            type: Boolean,
            default: false,
            required: false
        },
        infoUnitId: {
            type: Number,
            default: 0
        },
        link: {
            type: String,
            default: ""
        },
        padding: {
            type: String,
            default: ""
        },
        host: {
            type: String,
            default: ""
        },
        preview: {
            type: String,
            default: ""
        }
    },
    name: "YoutubeVideoPlayer",
    data() {
        return {
            didUpdate: false
        }
    },
    watch: {
        link() {
            this.didUpdate = true
            setTimeout(() => {
                this.didUpdate = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
