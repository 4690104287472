<template>
    <div class="comments-widget">
        <default-title v-if="comments.length" class="mb-15" :size="16" :line-height="21" :weight="500">
            {{ t("course.practice.comments") }}
        </default-title>

        <LoaderBlock v-if="loaders.all" :green-color="true" />
        <template v-else>
            <div ref="wrapper" class="comments-wrapper custom-scroll">
                <div class="comments-widget-item" :key="comment.id" v-for="comment in comments">
                    <flex-container>
                        <CommentAuthor :author="comment.user" class="mr-10" />
                        <div>
                            <default-description :weight="600" class="black">
                                {{ comment.user.name }}
                                <span
                                    :style="{ pointerEvents: canBeChanged(comment.user) ? 'all' : 'none' }"
                                    style="display: inline-block; position: relative"
                                    v-if="!isPublic()"
                                    :data-tooltip="
                                        t(
                                            comment.is_private
                                                ? 'course.practice.comment_unavailable_student'
                                                : 'course.practice.comment_available_student'
                                        )
                                    "
                                    @click.stop.prevent="changeVisibility($event, comment)"
                                    @mouseleave="isHover($event, false)"
                                    @mouseover="
                                        isHover($event, true, {
                                            center: true,
                                            move_up: 17
                                        })
                                    "
                                >
                                    <EyeStrokedIcon
                                        fill="#D14141"
                                        hovered
                                        style="margin-bottom: -2px"
                                        v-if="comment.is_private"
                                        width="14"
                                        height="14"
                                    />
                                    <EyeIcon
                                        fill="#3965FF"
                                        hovered
                                        style="margin-bottom: -2px"
                                        v-else
                                        width="14"
                                        height="14"
                                    />
                                </span>
                            </default-description>
                            <default-description>
                                {{ formatdate(comment.created_at, "DD MMMM YYYY, HH:mm") }}
                            </default-description>
                        </div>
                    </flex-container>
                    <default-description class="mt-5 mr-10" style="font-style: italic">
                        <div v-html="getHtml(comment.text)"></div>
                    </default-description>
                </div>
            </div>
            <div class="relative mt-15" v-if="!isPublic()">
                <LoaderBlock class="comment-loader" v-if="loaders.comment" :green-color="true" />
                <label :class="{ load: loaders.comment }" class="comments-widget-label" :for="`area${commentable_id}`">
                    {{ t("course.practice.new_comment") }}
                </label>
                <textarea
                    :class="{ disabled: loaders.comment }"
                    :id="`area${commentable_id}`"
                    v-model="text"
                    @keydown.enter.prevent.exact="submit"
                    @keydown.ctrl.enter.prevent="newLine"
                    @keydown.alt.enter.prevent="newLine"
                    @keydown.shift.enter.prevent="newLine"
                    ref="area"
                    :placeholder="t('course.practice.leave_comment')"
                    class="comments-widget-textarea custom-scroll"
                ></textarea>
            </div>
            <flex-container
                v-if="!isPublic()"
                @click.native="available = !available"
                class="mt-10 cursor-pointer"
                align="center"
            >
                <PracticeCheck :active="available" />
                <default-description style="margin-top: 4px" class="black" :weight="400">
                    {{ t("course.practice.comment_available") }}
                </default-description>
            </flex-container>
            <flex-container class="mt-20" v-if="!isPublic()">
                <DefaultButton
                    class="mr-10"
                    :width="190"
                    :height="45"
                    @click="$emit('close')"
                    background="cancel"
                    :text="t('expert.cancel')"
                />
                <DefaultButton
                    :width="190"
                    :height="45"
                    :disabled="!text || loaders.comment"
                    @click="submit()"
                    background="green"
                    :text="t('create-course.send')"
                />
            </flex-container>
        </template>
    </div>
</template>

<script>
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import EyeIcon from "@icons/EyeIcon.vue"
import EyeStrokedIcon from "@icons/EyeStrokedIcon.vue"
import axios from "~axios"
import tooltipOwn from "@mixins/tooltipOwn"
import moment from "moment-timezone"
import DefaultButton from "../../../Buttons/DefaultButton.vue"
import FlexContainer from "../../../Containers/FlexContainer.vue"
import LoaderBlock from "../../../Loaders/LoaderBlock.vue"
import PracticeCheck from "../inputs/PracticeCheck.vue"
import DefaultDescription from "../../../Typography/DefaultDescription.vue"
import CommentAuthor from "../../../Comments/CommentAuthor.vue"

import "moment/dist/locale/ru"
import "moment/dist/locale/uk"
import "moment/dist/locale/it"
import "moment/dist/locale/es"
import "moment/dist/locale/de"
import "moment/dist/locale/pl"

moment.locale(window.app_current_locale)

export default {
    components: {
        DefaultTitle,
        DefaultButton,
        EyeStrokedIcon,
        EyeIcon,
        PracticeCheck,
        LoaderBlock,
        DefaultDescription,
        FlexContainer,
        CommentAuthor
    },
    mixins: [tooltipOwn],
    props: {
        commentable_type: {
            type: String,
            default: "question_id"
        },
        commentable_id: {
            type: Number,
            default: 0
        },
        attempt_id: {
            type: Number,
            default: 0
        },
        public: {
            type: Boolean,
            default: false
        }
    },
    name: "AttemptCommentsWidget",
    data() {
        return {
            comments: [],
            text: "",
            available: true,
            loaders: {
                all: false,
                comment: false
            }
        }
    },
    created() {
        this.getComments()
    },
    methods: {
        getHtml(text) {
            return new DOMParser().parseFromString(text, "text/html").documentElement.textContent
        },
        canBeChanged(user) {
            return (
                this.$store &&
                this.$store.getters["user/currentUser"] &&
                this.$store.getters["user/currentUser"].id === user.id
            )
        },
        isPublic() {
            return this.public
        },
        formatdate(value, format = "MMMM DD, YYYY") {
            return moment(value).format(format)
        },
        async submit() {
            if (!this.text) {
                return
            }

            this.loaders.comment = true

            const params = {
                commentable_type: "quiz-attempt",
                commentable_id: this.attempt_id,
                text: this.text,
                is_private: !this.available,
                [this.commentable_type]: this.commentable_id
            }

            const { data } = await axios.post(`${this.public ? "/public" : ""}/comments`, params)
            this.loaders.comment = false
            this.comments.unshift(data.data)
            this.text = ""
            this.$emit("add")
            this.$nextTick(() => {
                if (this.$refs.wrapper) {
                    this.$refs.wrapper.scrollTop = 0
                }
            })
        },
        newLine(e) {
            document.execCommand("insertLineBreak")
            e.preventDefault()
        },
        changeVisibility(e, comment) {
            axios.post(`/comments/${comment.id}/${comment.is_private ? "public" : "private"}`)
            comment.is_private = !comment.is_private
            this.isHover(e, false)
        },
        async getComments() {
            this.loaders.all = true
            try {
                setTimeout(async () => {
                    let url = `${this.public ? "/public" : ""}/comments/quiz-attempt/${this.attempt_id}`

                    url += `?${this.commentable_type}=${this.commentable_id}`

                    const { data } = await axios.get(url)

                    if (!this.public) {
                        this.comments = data.comments.data
                    } else {
                        this.comments = data.data
                    }

                    this.loaders.all = false
                    this.$nextTick(() => {
                        if (this.$refs.wrapper) {
                            this.$refs.wrapper.scrollTop = 0
                        }
                    })
                }, 300)
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>

<style scoped lang="sass">
.comments-widget
    width: 100%
    .comments-widget-textarea
        border: 1px solid #E0E0EC
        border-radius: 8px
        width: 100%
        min-height: 130px
        padding: 14px 32px
        font-size: 13px
        font-family: 'Lato', sans-serif
        font-weight: 500
        line-height: 17px
        &::placeholder
            color: #B5C1D2
            font-weight: 500
    .comments-widget-item
        &:not(&:last-child)
            margin-bottom: 15px
    .comments-wrapper
        max-height: 290px
        overflow-y: auto
    .comments-widget-label
        position: absolute
        font-weight: 600
        font-size: 10px
        line-height: 13px
        color: #B5C1D2
        font-family: Lato, sans-serif
        padding: 0 4px
        background: #fff
        top: -5px
        left: 28px
        z-index: 4
        &.load
            color: rgba(#B5C1D2, 0.4)
    .comment-loader
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        z-index: 5
</style>
