<template>
    <div class="tooltip-on-hover" :class="additionalClass" :style="getRootStyle()">
        <slot></slot>
        <div class="tooltip-on-hover-triangle" :style="getTriangleStyle()"></div>
    </div>
</template>

<script>
export default {
    name: "TooltipOnHover",
    props: {
        additionalClass: String,
        toTop: Number,
        toHorizontal: Number,
        triangleLeft: Boolean,
        triangleRight: Boolean,
        textAlign: String,
        width: Number,
        whiteSpace: String
    },
    methods: {
        getTriangleStyle() {
            const style = {}

            if (this.toHorizontal) {
                style.transform = `translateX(${-this.toHorizontal / 2 - 10}px) !important`
            }

            if (this.triangleLeft) {
                style.transform = `none !important`
                style.left = `20px !important`
            }

            if (this.triangleRight) {
                style.transform = `none !important`
                style.left = `calc(100% - 30px) !important`
                //style.right = `20px !important`
            }

            return style
        },
        getRootStyle() {
            const style = {
                top: this.toTop && `${this.toTop}px`,
                textAlign: this.textAlign && this.textAlign,
                width: this.width && `${this.width}px`,
                whiteSpace: this.whiteSpace && this.whiteSpace
            }

            if (this.toHorizontal) {
                style.left = `${this.toHorizontal}px !important`
            }
            return style
        }
    }
}
</script>

<style lang="sass">
.show-tooltip-on-hover
    position: relative
    &:hover
        .tooltip-on-hover
            opacity: 1

.tooltip-on-hover
    position: absolute
    top: -35px
    left: 50%
    transform: translate(-50%, -50%)
    background-color: #fff
    white-space: nowrap
    border-radius: 10px
    font-size: 12px
    color: #52565C
    padding: 6px 14px
    box-shadow: 0 2px 15px rgba(128, 158, 191, 0.25)
    z-index: 9999999999
    pointer-events: none
    opacity: 0
    transition: 250ms
    text-transform: initial !important
    margin-left: 0 !important
    .tooltip-on-hover-triangle
        content: ""
        position: absolute
        top: 100%
        left: 50%
        transform: translateX(-50%)
        border: 7px solid transparent
        border-top-color: #fff
    @media screen and (max-width: 960px)
        left: -15px
        &:after
            left: 25px
    &__subtitle,
    &__text
        font-weight: 400
        font-size: 12px
        line-height: 14px

    &__subtitle
        color: #52565C
    &__text
        color: #151515
</style>
