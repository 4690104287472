import { QUESTION_TYPES } from "@components/Practice/PracticeQuestions/_types"
import PracticeRepository from "@components/Practice/shared/repository/practice-repository"
import LessonRepository from "@lesson/shared/repository/lesson-repository"
import cloneDeep from "lodash.clonedeep"
import QuestionsMap from "@components/Practice/PracticeQuestions/_map"
import { EventBus } from "~events"
import { ANSWER_TYPES, QUESTION_DISPLAY_TYPES, REANSWER_TYPES } from "@components/Practice/common/_types"

import TimerMixin from "../common/timer/_timer"

import BugIcon from "@icons/BugIcon.vue"
import { Debouncer, handleError } from "@helpers"

export const goNextQuestionOpts = {
    prevent: false,
    is_finish: false,
    index: null,
    is_force_finish: false,
    is_check: false
}

export const isDisableChangeButton = question =>
    [
        /* QUESTION_TYPES.SINGLE_ANSWER,
        QUESTION_TYPES.MULTIPLE_ANSWER,
        QUESTION_TYPES.TEXT_GRAPHIC_ANSWER,
        QUESTION_TYPES.INTERVIEW,
        QUESTION_TYPES.LIKERT_SCALE,
        QUESTION_TYPES.RATING,
        QUESTION_TYPES.REACTION,
        QUESTION_TYPES.YES_NO,
        QUESTION_TYPES.NET_PROMOTE*/
    ].includes(question.type.slug)

export const isInvalidQuestion = question => {
    const defaultCase = () => {
        if (!question.answers && question.is_required) {
            return false
        }
        return (
            (question.is_required && !question.answers.find(a => a.is_correct)) ||
            (question.is_required &&
                question.answers.find(a => a.is_correct) &&
                question.answers.find(a => a.is_correct).type === 2 &&
                !question.answers.find(a => a.is_correct).text_answer)
        )
    }

    switch (question.type.slug) {
        case QUESTION_TYPES.TEXT_MEDIA_ANSWER: {
            if (question.is_required) {
                if (question.upload_files && question.upload_files.length) {
                    return false
                }
                return !question.text_answer
            }
            return false
        }
        case QUESTION_TYPES.CONFORMITY_ANSWER: {
            if (question.is_required) {
                return !question.is_allowed
            }
            return false
        }
        case QUESTION_TYPES.WORD_ORDER: {
            if (question.is_required) {
                return !question.statements.find(
                    statement => statement.correct_answers && statement.correct_answers.length
                )
            }
            return false
        }
        case QUESTION_TYPES.CHOOSE_FROM_LIST: {
            if (question.is_required) {
                return !question.statements.find(
                    statement => statement.selected_answers && statement.selected_answers.length
                )
            }
            return false
        }
        case QUESTION_TYPES.MULTI_CHOOSE_FROM_LIST: {
            if (question.is_required) {
                return !question.statements.find(
                    statement => statement.selected_answers && statement.selected_answers.length
                )
            }
            return false
        }
        case QUESTION_TYPES.PASTE_WORD: {
            if (question.is_required) {
                return !question.statements.find(statement => statement.answer_groups[0].text_answer)
            }
            return false
        }
        case QUESTION_TYPES.FILL_GAPS: {
            if (question.is_required) {
                return !question.is_allowed
            }
            return false
        }
        case QUESTION_TYPES.SORTING: {
            return false
        }
        case QUESTION_TYPES.WORD_FROM_CHARS: {
            if (question.is_required) {
                return !question.is_allowed
            }
            return false
        }
        default: {
            return defaultCase()
        }
    }
}

export const isCompletedQuestion = question => {
    const defaultCase = () => {
        if (!question.answers) {
            return false
        }
        return (
            !question.answers.find(a => a.is_correct) ||
            (question.answers.find(a => a.is_correct) &&
                question.answers.find(a => a.is_correct).type === 2 &&
                !question.answers.find(a => a.is_correct).text_answer)
        )
    }

    switch (question.type.slug) {
        case QUESTION_TYPES.TEXT_MEDIA_ANSWER: {
            if (question.upload_files && question.upload_files.length) {
                return false
            }
            return !question.text_answer
        }
        case QUESTION_TYPES.CONFORMITY_ANSWER: {
            return !question.is_allowed
        }
        case QUESTION_TYPES.WORD_ORDER: {
            return !question.statements.find(
                statement => statement.shuffled_answers && statement.shuffled_answers.find(answer => answer.moved)
            )
        }
        case QUESTION_TYPES.CHOOSE_FROM_LIST: {
            return !question.statements.find(
                statement => statement.selected_answers && statement.selected_answers.length
            )
        }
        case QUESTION_TYPES.MULTI_CHOOSE_FROM_LIST: {
            return !question.statements.find(
                statement => statement.selected_answers && statement.selected_answers.length
            )
        }
        case QUESTION_TYPES.PASTE_WORD: {
            return !question.statements.find(statement => statement.answer_groups[0].text_answer)
        }
        case QUESTION_TYPES.FILL_GAPS: {
            return !question.is_allowed
        }
        case QUESTION_TYPES.SORTING: {
            return false
        }
        case QUESTION_TYPES.WORD_FROM_CHARS: {
            return !question.is_allowed
        }
        default: {
            return defaultCase()
        }
    }
}

export default {
    props: {
        unitId: {
            type: Number,
            default: 0
        },
        quiz: {
            type: Object,
            default: null
        },
        lesson: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            question: null,
            loading: false,
            loaders: {
                disable: false,
                finish: false
            },
            errorQuestion: null,
            didUpdate: false,
            REANSWER_TYPES: REANSWER_TYPES,
            errors: [],
            didUpdateActions: false,
            callout: 0,
            initQuestions: [],
            QUESTION_DISPLAY_TYPES,
            debouncer: {},
            isShowFinishModal: false
        }
    },
    mixins: [TimerMixin],
    created() {
        this.initQuestions = cloneDeep(
            this.quiz.questions.map(question => ({ ...question, text_answer: "", binds: [] }))
        )

        if (
            this.quiz.attempt &&
            this.quiz.attempt.results &&
            this.quiz.attempt.results.length &&
            this.quiz.attempt.status_id !== 3
        ) {
            const withoutResult = this.quiz.questions.find(question => !question.has_result_answer)

            if (
                this.quiz.reanswer_type === REANSWER_TYPES.VIEW_READONLY ||
                this.quiz.reanswer_type === REANSWER_TYPES.VIEW_EDIT ||
                this.quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.ALL
            ) {
                this.setPreviousResults(this.quiz.attempt.results)
            }

            if (this.quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.SEPARATELY) {
                if (withoutResult) {
                    const index = this.quiz.questions.indexOf(withoutResult)

                    for (let i = 0; i < index; i++) {
                        this.quiz.questions[i].completed = true
                    }

                    this.updateActions()

                    this.setQuestion(this.quiz.questions[index])
                } else {
                    /*  this.quiz.questions = this.quiz.questions.filter(
                        question => !this.quiz.attempt.results.find(result => result.quiz_question_id === question.id)
                    )*/

                    this.setQuestion(this.quiz.questions[this.quiz.questions.length - 1])
                }
            }
        } else {
            this.setQuestion(this.quiz.questions[0])
        }
    },
    methods: {
        isInteractive() {
            return !(this.question.has_stopped_info && !!this.question.viewed_result && this.isDisplayQuestionResult)
        },
        async answerOneQuestion(payload) {
            try {
                const result = await PracticeRepository.answers({
                    lesson_id: this.lesson.id,
                    attempt_id: this.quiz?.attempt?.id,
                    unit_id: this.unitId,
                    payload
                })

                if (result.data) {
                    const attempt = result.data

                    if (this.isAttemptTimeLeft()) {
                        attempt.finished = true
                    }

                    this.quiz.attempt = attempt

                    this.$forceUpdate()
                }
            } catch (e) {
                if (
                    e?.response?.data?.errors?.text_answer &&
                    payload.quiz_question_id &&
                    this.quiz.questions.find(q => q.id === payload.quiz_question_id).type.slug ===
                        QUESTION_TYPES.TEXT_MEDIA_ANSWER
                ) {
                    this.errors = e.response.data.errors.text_answer
                } else {
                    this.$notify({
                        position: "top-right",
                        color: "danger",
                        time: 5000,
                        title: this.t("expert.something_went_wrong"),
                        text: e?.response?.data?.message || e?.response?.data?.error || e,
                        icon: BugIcon
                    })
                }
            }
        },
        async onQuestionCallout(question) {
            this.errorQuestion = null
            this.errors = []

            if (this.quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.ALL) {
                if (!this.quiz.attempt) {
                    await this.startAttempt()
                } else {
                    window.onbeforeunload = function (e) {
                        e.preventDefault()
                        return "Are you sure ?"
                    }
                }

                question.completed = true

                let questionDebouncer = this.debouncer[question.id] ?? null

                if (!questionDebouncer) {
                    questionDebouncer = new Debouncer()

                    this.debouncer[question.id] = questionDebouncer
                }

                questionDebouncer.exec(() => {
                    const payload = this.createAttemptPayload(false, question)

                    this.updateActions()

                    this.answerOneQuestion(payload)
                }, 5000)
            } else if (!this.isInteractive()) {
                if (this.isDisplayQuestionResult && this.question.viewed_result) {
                    if (question?.type?.slug === QUESTION_TYPES.FILL_GAPS) {
                        if (Symbol.iterator in Object(this.question.viewed_result.results)) {
                            for (const result of this.question.viewed_result.results) {
                                const { quiz_statement_id, text_answer, quiz_answer_group_id } = result
                                const statement = this.question.statements.find(s => s.id === quiz_statement_id)

                                if (statement) {
                                    const ag = statement.answer_groups.find(ag => ag.id === quiz_answer_group_id)

                                    if (ag) {
                                        ag.active = { answer_group_id: quiz_answer_group_id, text_answer }
                                    }
                                }
                            }

                            const results = cloneDeep(this.question.viewed_result.results)

                            this.$nextTick(() => {
                                question.results = results

                                if (Array.isArray(this.quiz.attempt.results)) {
                                    this.quiz.attempt.results = [
                                        ...this.quiz.attempt.results.filter(r => r.quiz_question_id !== question.id),
                                        ...results
                                    ]
                                }
                                this.forseRefreshQuestion()
                            })
                        }
                    }

                    delete this.question.viewed_result
                }
            }

            this.callout++
        },
        async submitAttempt(is_force_finish = false) {
            if (is_force_finish) {
                this.isShowFinishModal = false
            }

            if (!is_force_finish) {
                if (
                    this.quiz.questions.length - 1 >
                    this.quiz.questions.filter(question => question.completed).length
                ) {
                    this.isShowFinishModal = true
                    return
                }
            }

            this.loaders.finish = true

            for (const question of this.quiz.questions) {
                if (question.type.slug === QUESTION_TYPES.SORTING) {
                    const payload = this.createAttemptPayload(false, question)
                    question.completed = true
                    this.updateActions()
                    await this.answerOneQuestion(payload)
                }
            }

            try {
                this.clearQuestionsDebauncer()

                const payload = this.quiz.questions.map(q => this.createAttemptPayload(false, q))

                if (!this.quiz.attempt) {
                    await this.startAttempt()
                }

                const result = await PracticeRepository.finish({
                    attempt_id: this.quiz.attempt.id,
                    payload
                })

                if (result.data) {
                    if (result.data.success && result.data.attempt) {
                        const attempt = result.data.attempt

                        this.quiz.attempt = attempt

                        this.replaceContents(attempt)
                    } else if (!result.data.success) {
                        if (result.data.questions && result.data.questions.length && result.data.questions[0].id) {
                            this.errorQuestion = result.data.questions[0].id
                        }

                        const el = document.querySelector(`[data-question='${this.errorQuestion}']`)

                        if (this.errorQuestion && el) {
                            el.scrollIntoView({ block: "center" })
                        }

                        const message = () => {
                            if (this.$notify) {
                                this.$notify({
                                    position: "top-right",
                                    color: "danger",
                                    time: 5000,
                                    title: this.t("expert.something_went_wrong"),
                                    text: result.data.message,
                                    icon: BugIcon
                                })
                            }
                        }

                        if (window.onscrollend !== undefined) {
                            window.addEventListener("scrollend", message, { once: true })
                        } else {
                            setTimeout(message, 300)
                        }
                    }
                }
            } catch (e) {
                this.$notify({
                    position: "top-right",
                    color: "danger",
                    time: 5000,
                    title: this.t("expert.something_went_wrong"),
                    text: e?.response?.data?.message || e?.response?.data?.error || e,
                    icon: BugIcon
                })
                this.loaders.finish = false
            } finally {
                this.loaders.finish = false
            }
        },
        async clearAttempt() {
            this.$el.scrollIntoView()

            this.clearQuestionsDebauncer()

            const clear = () => {
                this.quiz.questions = cloneDeep(this.initQuestions)
                if (this.quiz.attempt) {
                    this.quiz.attempt.results = []
                }
                this.forseRefreshQuestion()
                setTimeout(() => {
                    this.loaders.disable = false
                }, 300)
            }

            this.loaders.disable = true

            try {
                const result = await PracticeRepository.clear({
                    attempt_id: this.quiz.attempt.id
                })
                if (result) {
                    if (window.onscrollend !== undefined) {
                        window.addEventListener("scrollend", clear, { once: true })
                    } else {
                        setTimeout(clear, 300)
                    }
                }
            } catch (e) {
                this.loaders.disable = false
            }
        },
        clearQuestionsDebauncer() {
            // eslint-disable-next-line no-unused-vars
            Object.entries(this.debouncer).forEach(([_, questionDebouncer]) => questionDebouncer.clear())
        },
        updateActions() {
            this.didUpdateActions = true
            this.$nextTick(() => {
                this.didUpdateActions = false
            })
        },
        getQuestionResults(question) {
            if (
                this.quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.ALL &&
                this.quiz.attempt &&
                this.quiz.attempt.results
            ) {
                return this.quiz.attempt.results.filter(r => r.quiz_question_id === question.id)
            }

            if (this.question.has_stopped_info && !!this.question.viewed_result && this.isDisplayQuestionResult) {
                return this.question.viewed_result.results
            }

            if (
                this.quiz.reanswer_type === REANSWER_TYPES.VIEW_READONLY ||
                this.quiz.reanswer_type === REANSWER_TYPES.VIEW_EDIT
            ) {
                if (!this?.quiz?.attempt?.results) {
                    if (question.results) {
                        return question.results
                    }
                    return null
                }
                return this.quiz.attempt.results.filter(r => r.quiz_question_id === question.id)
            }
            return null
        },
        setPreviousResults(results) {
            for (const result of results) {
                const question = this.quiz.questions.find(question => question.id === result.quiz_question_id)

                question.completed = true

                this.updateActions()

                if (question.type.slug === QUESTION_TYPES.SORTING) {
                    question.answers[result.answer_order - 1] = {
                        ...result.answer,
                        is_correct: true
                    }
                }

                if (question.type.slug === QUESTION_TYPES.TEXT_MEDIA_ANSWER) {
                    question.text_answer = result.text_answer
                    question.upload_files = result.files || []
                }

                if (question.type.slug === QUESTION_TYPES.PASTE_WORD) {
                    for (const statement of question.statements) {
                        const resultStatement = results.find(result => result.quiz_statement_id === statement.id)
                        if (resultStatement && resultStatement.text_answer) {
                            statement.answer_groups[0].text_answer = resultStatement.text_answer
                        }
                    }
                }

                if (
                    question.type.slug === QUESTION_TYPES.CHOOSE_FROM_LIST ||
                    question.type.slug === QUESTION_TYPES.MULTI_CHOOSE_FROM_LIST
                ) {
                    for (const statement of question.statements) {
                        const resultAnswers = results
                            .filter(result => result.quiz_statement_id === statement.id)
                            .map(r => r.answer)
                        if (resultAnswers && resultAnswers.length) {
                            statement.selected_answers = resultAnswers
                        }
                    }
                }

                if (question.type.slug === QUESTION_TYPES.WORD_ORDER) {
                    for (const statement of question.statements) {
                        const resultStatement = results.find(result => result.quiz_statement_id === statement.id)
                        if (resultStatement && resultStatement.text_answer) {
                            statement.resultStatement = resultStatement.text_answer.split("/")
                        }
                    }
                }

                if (
                    [
                        QUESTION_TYPES.SINGLE_ANSWER,
                        QUESTION_TYPES.YES_NO,
                        QUESTION_TYPES.NET_PROMOTE,
                        QUESTION_TYPES.REACTION,
                        QUESTION_TYPES.RATING,
                        QUESTION_TYPES.MULTIPLE_ANSWER,
                        QUESTION_TYPES.LIKERT_SCALE,
                        QUESTION_TYPES.INTERVIEW
                    ].includes(question.type.slug)
                ) {
                    const answer = question.answers.find(answer => answer.id === result.quiz_answer_id)

                    if (answer) {
                        answer.is_correct = true
                    }

                    if (answer && answer.type && answer.type === ANSWER_TYPES.CUSTOM) {
                        answer.text_answer = result.text_answer
                    }
                }
            }
        },
        isQuizTimeLeft() {
            if (!this.quiz.attempt) {
                return false
            }

            return this.isTimeLeft(this.quiz.attempt.left_time_in_seconds)
        },
        isQuestionTimeLeft() {
            if (!this.quiz.attempt) {
                return false
            }

            if (!this.question) {
                return false
            }

            return this.isTimeLeft(this.question.left_time_in_seconds)
        },
        isAttemptTimeLeft() {
            return this.isQuizTimeLeft() || (this.isQuestionTimeLeft() && this.isLastQuestion())
        },
        async startAttempt() {
            const result = await PracticeRepository.attempt({
                lesson_id: this.lesson.id,
                quiz_id: this.quiz.id,
                unit_id: this.unitId
            })

            if (result) {
                this.$set(this.quiz, "attempt", result.data)
                return result.data
            }
        },
        forseRefreshQuestion() {
            this.didUpdate = true
            this.$nextTick(() => {
                this.didUpdate = false
            })
        },
        replaceContents(attempt) {
            if (attempt && attempt.status_id && attempt.status_id === 1 && this.lesson.section_id) {
                if (this.quiz.is_checkpoint) {
                    //window.scrollTo({ top: 0, behavior: "smooth" })
                }
                setTimeout(async () => {
                    await LessonRepository.replaceContents({ section: this.lesson.section_id })
                }, 300)
            }
        },
        goPrevQuestion() {
            if (!this.isFirstQuestion()) {
                this.setQuestion(this.quiz.questions[this.getQuestionIndex() - 1])

                this.$nextTick(() => {
                    if (this.isDisplayQuestionResult) {
                        //delete this.question.viewed_result
                    }
                    this.forseRefreshQuestion()
                })
            }
        },
        async goNextQuestion(opts = goNextQuestionOpts) {
            if (!opts) {
                opts = {}
            }

            opts = {
                ...goNextQuestionOpts,
                ...opts
            }

            this.errors = []

            if (opts.is_force_finish) {
                this.isShowFinishModal = false
            }

            if (opts.is_finish && !opts.is_force_finish && !this.isLastQuestion()) {
                if (
                    this.quiz.questions.length - 1 >
                    this.quiz.questions.filter(question => question.completed).length
                ) {
                    this.isShowFinishModal = true
                    return
                } else {
                    this.goNextQuestion({ is_force_finish: true, is_finish: true })
                }
            }

            if (
                opts.is_check &&
                !this.isInteractive() &&
                !isDisableChangeButton(this.question) &&
                !opts.is_force_finish
            ) {
                await this.onQuestionCallout(this.question)
                return
            }

            try {
                let isDeletedResult = false

                if (
                    this.isDisplayQuestionResult &&
                    this.question.viewed_result &&
                    this.quiz.reanswer_type === REANSWER_TYPES.NONE
                ) {
                    //delete this.question.viewed_result
                    //isDeletedResult = true
                }

                if (
                    this.quiz.reanswer_type === REANSWER_TYPES.VIEW_READONLY &&
                    this.question.has_result_answer &&
                    !opts.is_finish &&
                    !this.isLastQuestion() &&
                    !opts.is_check
                ) {
                    this.goNextIfNotLast(opts.index)
                    return
                }

                if (this.question.has_stopped_info && !opts.is_force_finish) {
                    if (
                        !this.isLastQuestion() &&
                        (this.quiz.reanswer_type !== REANSWER_TYPES.VIEW_EDIT || isDeletedResult) &&
                        !opts.is_check
                    ) {
                        this.goNextIfNotLast(opts.index)
                        return
                    }
                }

                this.loading = true

                if (!this.quiz.attempt) {
                    await this.startAttempt()
                }

                const payload = this.createAttemptPayload(opts.prevent, null, opts.is_finish)

                if (opts.is_check) {
                    payload.is_check = true
                }

                const result = await PracticeRepository.answers({
                    lesson_id: this.lesson.id,
                    attempt_id: this.quiz.attempt.id,
                    unit_id: this.unitId,
                    payload
                })

                if (result.data) {
                    const attempt = result.data

                    this.replaceContents(attempt)

                    this.question.has_result_answer = true

                    if (this.isAttemptTimeLeft()) {
                        //attempt.status_id = null
                        attempt.finished = true
                    }

                    if (this.quiz.attempt.status_id === 3 && attempt.status_id !== 3 && !opts.is_check) {
                        this.$set(this.quiz, "finalize", false)
                    }

                    if (result.quiz_question) {
                        if ("is_skipped" in result.quiz_question) {
                            this.question.is_skipped = result.quiz_question.is_skipped
                        }
                        this.question.comment = result.quiz_question.comment
                        this.question.comment_files = result.quiz_question.comment_files
                    }

                    const toDisplayComment =
                        !this.question.has_stopped_info && this.question.comment && this.question.comment_type_id !== 1
                    const toDisplayResult = this.isDisplayQuestionResult && opts.is_check

                    if (toDisplayComment || toDisplayResult) {
                        this.question.has_stopped_info = true
                        //this.quiz.temp_attempt = attempt

                        this.quiz.attempt = attempt

                        if (toDisplayResult) {
                            this.question.viewed_result = {
                                ...result.quiz_question,
                                is_viewed_result: true
                            }

                            this.$nextTick(() => {
                                this.forseRefreshQuestion()
                            })
                        }

                        return
                    }

                    if (result.finish_attempt_result && opts.is_finish) {
                        const attemptResult = result.finish_attempt_result

                        if (!attemptResult.success) {
                            if (this.$notify) {
                                this.$notify({
                                    position: "top-right",
                                    color: "danger",
                                    time: 5000,
                                    title: this.t("expert.something_went_wrong"),
                                    text: attemptResult.message,
                                    icon: BugIcon
                                })
                            }
                            if (attemptResult.questions && attemptResult.questions.length) {
                                const [question] = attemptResult.questions

                                const current = this.quiz.questions.find(q => q.id === question.id)

                                if (current) {
                                    this.goNextIfNotLast(this.quiz.questions.indexOf(current))
                                    return
                                }
                            }
                        }
                    }

                    if ((this.isLastQuestion() || opts.is_finish) && !opts.is_check) {
                        window.onbeforeunload = null
                        this.$emit("rerender")
                    }

                    if (
                        this.question.type.slug === QUESTION_TYPES.WORD_FROM_CHARS ||
                        this.question.type.slug === QUESTION_TYPES.WORD_ORDER ||
                        this.question.type.slug === QUESTION_TYPES.FILL_GAPS ||
                        this.question.type.slug === QUESTION_TYPES.CONFORMITY_ANSWER ||
                        this.question.type.slug === QUESTION_TYPES.SINGLE_ANSWER
                    ) {
                        this.forseRefreshQuestion()
                    }

                    this.quiz.attempt = attempt
                }
            } catch (e) {
                if (
                    e?.response?.data?.errors?.text_answer &&
                    this.question.type.slug === QUESTION_TYPES.TEXT_MEDIA_ANSWER
                ) {
                    this.errors = e.response.data.errors.text_answer
                } else if (this.$notify) {
                    this.$notify({
                        position: "top-right",
                        color: "danger",
                        time: 5000,
                        title: this.t("expert.something_went_wrong"),
                        text: e?.response?.data?.message || e?.response?.data?.error || e,
                        icon: BugIcon
                    })
                } else {
                    handleError(e)
                }

                if (this.isLastQuestion() && this.question.type.slug !== QUESTION_TYPES.TEXT_MEDIA_ANSWER) {
                    window.onbeforeunload = null
                    this.$emit("rerender")
                }

                return
            } finally {
                this.loading = false
            }

            if (opts.prevent) {
                return
            }

            this.goNextIfNotLast(opts.index)
        },
        goQuestionFromNavigation(index) {
            if (
                !isInvalidQuestion({
                    ...this.question,
                    is_required: true
                })
            ) {
                const currentIndex = this.getQuestionIndex()
                this.quiz.questions[currentIndex].completed = true

                this.goNextQuestion({ index })
            } else {
                this.goNextIfNotLast(index)
            }
        },
        goNextIfNotLast(index = null) {
            if (!this.isLastQuestion() || index || index === 0) {
                const currentIndex = this.getQuestionIndex()

                if (index === null) {
                    this.$el.scrollIntoView({ behavior: "smooth" })

                    this.quiz.questions[currentIndex].completed = true
                }

                const goTo = index === null ? currentIndex + 1 : index

                this.setQuestion(this.quiz.questions[goTo])
                this.forseRefreshQuestion()
            }
        },
        createAttemptPayload(prevent = false, q = null, is_finish = false) {
            const question = q || this.question

            const correct = question?.answers?.filter(answer => answer.is_correct)

            const payload = {
                quiz_question_id: question.id,
                answer_ids:
                    correct &&
                    correct.reduce((accumulator, current) => {
                        accumulator.push(current.id)
                        return accumulator
                    }, []),
                comment_id: null
            }

            if (is_finish) {
                payload.is_finish = is_finish
            }

            if (prevent) {
                payload.is_timeout = true
            }

            if (correct && correct.length && correct.find(answer => answer.type === ANSWER_TYPES.CUSTOM)) {
                payload.text_answer = correct.find(answer => answer.type === ANSWER_TYPES.CUSTOM).text_answer
            }

            if (question.type.slug === QUESTION_TYPES.TEXT_MEDIA_ANSWER) {
                payload.text_answer = question.text_answer
                if (question.upload_files) {
                    payload.files = question.upload_files.map(f => f.id)
                }
            }

            if (question.type.slug === QUESTION_TYPES.WORD_FROM_CHARS) {
                payload.text_answer = question.text_answer
                payload.answer_ids = [question.answers[0].id]
            }

            if (question.type.slug === QUESTION_TYPES.PASTE_WORD) {
                const answers = []

                for (const statement of question.statements) {
                    const [group] = statement.answer_groups
                    answers.push({
                        statement_id: statement.id,
                        answer_group_id: group.id,
                        text_answer: group.text_answer
                    })
                }

                payload.answers = answers
            }

            if (
                question.type.slug === QUESTION_TYPES.CHOOSE_FROM_LIST ||
                question.type.slug === QUESTION_TYPES.MULTI_CHOOSE_FROM_LIST
            ) {
                const answers = []

                for (const statement of question.statements) {
                    if (statement.selected_answers) {
                        answers.push(
                            ...statement.selected_answers.map(answer => ({
                                answer_id: answer.id,
                                answer_group_id: statement.answer_groups[0]?.id,
                                statement_id: statement.id,
                                text_answer: answer.text
                            }))
                        )
                    }
                }

                payload.answers = answers
            }

            if (question.type.slug === QUESTION_TYPES.WORD_ORDER) {
                const answers = []
                for (const statement of question.statements) {
                    if (statement.correct_answers && statement.correct_answers.length) {
                        if (statement.correct_answers.find(answer => this.isTrueRTL(answer.text))) {
                            answers.push({
                                statement_id: statement.id,
                                text_answer: cloneDeep(statement.correct_answers)
                                    .reverse()
                                    .map(answer => answer.text)
                                    .join("/")
                            })
                        } else {
                            answers.push({
                                statement_id: statement.id,
                                text_answer: statement.correct_answers.map(answer => answer.text).join("/")
                            })
                        }
                    } else {
                        answers.push({
                            statement_id: statement.id,
                            text_answer: statement.shuffled_answers.map(answer => answer.text).join("/")
                        })
                    }
                }
                payload.answers = answers
            }

            if (question.type.slug === QUESTION_TYPES.FILL_GAPS) {
                const answers = []
                for (const statement of question.statements) {
                    for (const answerGroup of statement.answer_groups) {
                        if (answerGroup.active) {
                            answers.push({
                                ...answerGroup.active,
                                statement_id: statement.id,
                                answer_group_id: answerGroup.id
                            })
                        }
                    }
                }
                payload.answers = answers
            }

            if (question.type.slug === QUESTION_TYPES.CONFORMITY_ANSWER) {
                const answers = []

                if (question.conformity_type === 1) {
                    const sortedAnswers = question.sortedAnswers || question.answers.map(a => a.id)

                    for (const answer of sortedAnswers) {
                        const values = Object.values(question.binds)

                        if (values.includes(answer)) {
                            const index = values.indexOf(answer)

                            const [bindStatement, bindAnswer] = Object.entries(cloneDeep(question.binds))[index]
                            answers.push({
                                answer_id: parseInt(bindAnswer),
                                statement_id: bindStatement
                            })
                        } else {
                            answers.push({
                                answer_id: answer,
                                statement_id: null
                            })
                        }
                    }
                }

                if (question.conformity_type === 2) {
                    for (const statement of question.conformityStatements) {
                        for (const answer of statement.answers) {
                            answers.push({
                                answer_id: parseInt(answer.id),
                                statement_id: statement.id
                            })
                        }
                    }
                }
                payload.answers = answers
            }

            return payload
        },
        isTrueRTL(s) {
            let ltrChars =
                    "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
                    "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
                rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
                rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]")

            return rtlDirCheck.test(s)
        },
        setAttempt(attempt) {
            this.quiz.attempt = attempt
            this.$set(this.quiz, "finalize", false)
            window.onbeforeunload = null
            this.$emit("rerender")
        },
        setQuestion(question) {
            this.question = question
            const active = this.quiz.questions.find(question => question.active)

            if (active) {
                this.quiz.questions[this.quiz.questions.indexOf(active)].active = false
            }

            this.quiz.questions[this.getQuestionIndex()].active = true

            const ref = this.$refs.navigation

            if (ref) {
                ref.setItems()
            }

            this.$forceUpdate()
        },
        isLastQuestion() {
            return this.getQuestionIndex() === this.quiz.questions.length - 1
        },
        isFirstQuestion() {
            return this.getQuestionIndex() === 0
        },
        getQuestionIndex() {
            return this.quiz.questions.indexOf(this.question)
        },
        getQuestionComponentBySlug(slug) {
            return QuestionsMap[slug]
        },
        quizIncludesQuestionWithTimer() {
            if (!this.quiz) {
                return false
            }
            return !!this.quiz.questions.find(q => q.timer)
        }
    },
    computed: {
        isReadonly() {
            if (this.quiz.reanswer_type === REANSWER_TYPES.VIEW_READONLY && this.question.has_result_answer) {
                return true
            }

            if (
                this.quiz.reanswer_type !== REANSWER_TYPES.VIEW_EDIT &&
                this.question.has_result_answer &&
                this.question.has_stopped_info
            ) {
                return true
            }

            return false
        },
        isDisplayQuestionResult() {
            return this.quiz?.type_show_question_result_attempt?.id !== 1
        }
    },
    watch: {
        callout() {
            this.updateActions()
        },
        "quiz.attempt": {
            deep: true,
            handler(val) {
                if (val.left_time_in_seconds && val.left_time_in_seconds <= 0 && !val.finished) {
                    EventBus.$emit("comments:send")
                    this.goNextQuestion({ prevent: true }).then()
                }
            }
        },
        quiz() {
            this.setQuestion(this.quiz.questions[0])
        }
    },
    beforeDestroy() {
        window.onbeforeunload = null
    }
}
