<template>
    <div class="practice-blue-alert">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PracticeBlueAlert"
}
</script>

<style scoped lang="sass">
.practice-blue-alert
    padding: 10px 14px
    border-radius: 7px
    border: 1px solid #B3DBF1
    background: #F1F8FC
    color: #52565C
    font-size: 16px
    font-weight: 400
    line-height: 21px
    max-width: 700px
    &.green
        border: 1px solid #15A06E
        background: #F9FEFC
    &.red
        border: 1px solid #DD4141
        background: #FFF6F6
    &.yellow
        border: 1px solid #D6E351
        background: #FEFFF4
    strong
        font-weight: 600
        color: #3965FF
</style>
