<template>
    <div class="mt-10" style="padding-top: 1px">
        <div
            class="practice-tga-block"
            :class="{ 'practice-tga-block_all': quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.ALL }"
        >
            <practice-red-alert
                v-if="quiz.attempt && quiz.attempt.left_time_in_seconds && quiz.attempt.left_time_in_seconds <= 0"
                class="mt-10 timer"
            >
                <strong class="inline">{{ t("course.practice.question_4") }}</strong>
                {{ t("course.practice.result_saved") }}
            </practice-red-alert>
            <practice-red-alert
                v-if="quiz.attempt && quiz.attempt.left_time_in_seconds && quiz.attempt.left_time_in_seconds > 0"
                class="mt-10"
            >
                {{ t("course.practice.quiz_left_time") }}:
                <PracticeTimer :attempt="quiz.attempt" />
            </practice-red-alert>
            <LoaderBlock v-if="loading" :green-color="true" style="min-height: 200px" />
            <div
                v-else
                :class="{
                    'practice-tga-block-disabled':
                        quiz.attempt && quiz.attempt.left_time_in_seconds && quiz.attempt.left_time_in_seconds <= 0
                }"
            >
                <div
                    v-for="(question, question_index) in quiz.questions.filter(
                        q => q.type === 'text_graphic_answer' || q.type.slug === 'text_graphic_answer'
                    )"
                    :key="question_index"
                >
                    <TextGraphicAnswer
                        :quiz="quiz"
                        :lesson="lesson"
                        :attempt="quiz.attempt"
                        :question="question"
                        :unit-id="unitId"
                        :course-id="lesson.course_id"
                        :question-number="0"
                        :interactive="true"
                        :class="question_index === 0 ? 'mt-20' : 'mt-30'"
                    />
                </div>
            </div>

            <PracticeActionsContainer
                @next="submitAttempt()"
                v-if="
                    quiz.questions_display_type_id !== QUESTION_DISPLAY_TYPES.SEPARATELY &&
                    quiz.attempt &&
                    quiz.attempt.status_id === 5
                "
                display-all
                :loaders="loaders"
                :quiz="quiz"
            />
        </div>
    </div>
</template>

<script>
import LoaderBlock from "@components/Loaders/LoaderBlock"
import { QUESTION_DISPLAY_TYPES } from "@components/Practice/common/_types"

import PracticeRedAlert from "@components/Practice/common/alerts/PracticeRedAlert"
import PracticeTimer from "@components/Practice/common/timer/PracticeTimer"
import PracticeActionsContainer from "@components/Practice/PracticeQuestions/components/PracticeActionsContainer.vue"

import TextGraphicAnswer from "@components/Practice/PracticeQuestions/PracticeTextGraphicAnswer"
import PracticeRepository from "@components/Practice/shared/repository/practice-repository"
import BugIcon from "@icons/BugIcon.vue"
import LessonRepository from "@lesson/shared/repository/lesson-repository"

import { EventBus } from "~events"

export default {
    props: {
        quiz: {
            type: Object,
            default: null
        },
        lesson: {
            type: Object,
            default: null
        },
        unitId: {
            type: Number,
            default: 0
        }
    },
    name: "PracticeOnlyTextGraphicAnswersBlock",
    components: {
        PracticeActionsContainer,
        TextGraphicAnswer,
        LoaderBlock,
        PracticeTimer,
        PracticeRedAlert
    },
    inject: ["startAttempt"],
    data() {
        return {
            loading: false,
            QUESTION_DISPLAY_TYPES,
            loaders: {
                finish: false
            }
        }
    },
    async created() {
        const allowedQuestions = this.quiz.questions.filter(q => q.is_answer_enabled)

        if (this.quiz.questions.length && !allowedQuestions.length && !this.quiz.attempt) {
            this.startAttempt({ lesson_id: this.lesson.id, quiz_id: this.quiz.id, unit_id: this.unitId })
        }
    },
    methods: {
        async submitAttempt() {
            this.loaders.finish = true

            try {
                const result = await PracticeRepository.finish({
                    attempt_id: this.quiz.attempt.id
                })
                if (result.data) {
                    if (result.data.success && result.data.attempt) {
                        this.quiz.attempt = result.data.attempt

                        if (this.lesson?.section_id) {
                            setTimeout(async () => {
                                await LessonRepository.replaceContents({ section: this.lesson.section_id })
                            }, 300)
                        }
                    } else if (!result.data.success) {
                        const message = () => {
                            if (this.$notify) {
                                this.$notify({
                                    position: "top-right",
                                    color: "danger",
                                    time: 5000,
                                    title: this.t("expert.something_went_wrong"),
                                    text: result.data.message,
                                    icon: BugIcon
                                })
                            }
                        }

                        setTimeout(message, 300)
                    }
                }
            } catch (e) {
                this.loaders.finish = false
            } finally {
                this.loaders.finish = false
            }
        }
    },
    watch: {
        "quiz.attempt": {
            deep: true,
            handler(val) {
                if (val && val.left_time_in_seconds <= 0 && !val.finished) {
                    EventBus.$emit("comments:send")
                    val.finished = true
                }
            }
        }
    }
}
</script>

<style lang="sass">
.practice-tga-block-disabled
    .comments-create, .comment__add-answer
        opacity: .3
        user-select: none
        pointer-events: none
        cursor: default
.practice-tga-block
    &:not(&_all)
        .practice-question__btn, .practice-next-action, .practice-actions-container
            display: none !important
</style>
