<template>
    <div :style="questionStyle()" class="practice-question">
        <PracticeQuestionInfo :number="questionNumber" :question="question" :quiz="quiz" :name="question.name" />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <FilesNewView v-if="question.files && question.files.length" class="mt-20" :files="question.files" />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers"
            :class="{ 'practice-question__answers_disabled': disabledByTimer() }"
        >
            <template v-if="isAllAnswersChoice() && completed">
                <PracticeConformityManyToOneCombined
                    @callout="$emit('callout')"
                    @update:action="forceUpdate()"
                    :attempt="attempt"
                    :is-all-answers-choice="true"
                    :lesson="lesson"
                    :interactive="interactive"
                    :viewable="viewable"
                    :readonly="readonly"
                    :results="getResults()"
                    :quiz="quiz"
                    :checkable="checkable"
                    :type-show="typeShow()"
                    :statements="question.statements"
                    :answers="question.answers"
                    :expert-view="expertView"
                    :question="question"
                    :binds="question.binds"
                />
            </template>
            <template v-else-if="question.statements.length === question.answers.length && completed">
                <PracticeConformityOneToOne
                    v-if="$screenWidth > 760"
                    @callout="$emit('callout')"
                    @update:action="forceUpdate()"
                    @set:empty="question.binds = {}"
                    :attempt="attempt"
                    :lesson="lesson"
                    :question="question"
                    :interactive="interactive"
                    :checkable="checkable"
                    :viewable="viewable"
                    :quiz="quiz"
                    :readonly="readonly"
                    :results="getResults()"
                    :type-show="typeShow()"
                    :statements="question.statements"
                    :answers="question.answers"
                    :expert-view="expertView"
                />

                <PracticeConformityOneToOneMobile
                    v-else
                    @callout="$emit('callout')"
                    @update:action="forceUpdate()"
                    :attempt="attempt"
                    :lesson="lesson"
                    :question="question"
                    :interactive="interactive"
                    :readonly="readonly"
                    :quiz="quiz"
                    :checkable="checkable"
                    :viewable="viewable"
                    :results="getResults()"
                    :type-show="typeShow()"
                    :statements="question.statements"
                    :answers="question.answers"
                    :expert-view="expertView"
                />
            </template>

            <PracticeConformityManyToOne
                v-else-if="completed"
                @callout="$emit('callout')"
                @update:action="forceUpdate()"
                :attempt="attempt"
                :lesson="lesson"
                :quiz="quiz"
                :interactive="interactive"
                :viewable="viewable"
                :readonly="readonly"
                :checkable="checkable"
                :results="getResults()"
                :type-show="typeShow()"
                :statements="question.statements"
                :answers="question.answers"
                :expert-view="expertView"
                :question="question"
                :binds="question.binds"
            />
        </div>

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer
            v-if="interactive && !didUpdate"
            :question="question"
            :quiz="quiz"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import PracticeConformityManyToOneCombined from "@components/Practice/PracticeQuestions/components/PracticeConformityManyToOneCombined.vue"
import PracticeMixin from "./_mixin"
import PracticeConformityOneToOneMobile from "./components/PracticeConformityOneToOneMobile"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import FilesNewView from "../../Files/FilesNewView"
import PracticeConformityOneToOne from "./components/PracticeConformityOneToOne"
import PracticeConformityManyToOne from "./components/PracticeConformityManyToOne"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"

export default {
    components: {
        PracticeConformityManyToOneCombined,
        PracticeConformityOneToOneMobile,
        PracticeActionsContainer,
        PracticeQuestionInfo,
        PracticeConformityOneToOne,
        PracticeConformityManyToOne,
        FilesNewView,
        PracticeQuestionDefaultOptions
    },
    mixins: [PracticeMixin],
    name: "PracticeConformityAnswer",
    data() {
        return {
            didUpdate: false,
            completed: false
        }
    },
    created() {
        this.question.conformity_type = this.question.statements.length === this.question.answers.length ? 1 : 2

        if (this.isAllAnswersChoice()) {
            this.question.conformity_type = 2
        }

        this.$set(this.question, "binds", {})

        for (const statement of this.question.statements) {
            this.$set(statement, "type", "statement")
        }
        for (const answer of this.question.answers) {
            this.$set(answer, "type", "answer")
        }

        this.completed = true
    },
    methods: {
        forceUpdate() {
            this.didUpdate = true
            this.$nextTick(() => {
                this.didUpdate = false
            })
        },
        getResults() {
            return this.results
        }
    }
}
</script>

<style lang="sass">
.statements-modal
    padding: 10px
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
    width: 100%
    border-radius: 7px
    position: absolute
    top: 100%
    background: #fff
    max-height: 250px
    padding-right: 4px
    z-index: 9999999
    .custom-scroll
        max-height: 200px
        overflow: auto
    .statements-modal-item
        margin-bottom: 2px
        padding: 11px 20px
        font-size: 13px
        font-weight: 400
        color: #52565C
        border-radius: 5px
        transition: .2s

        &:hover
            background: #F2F7FF
</style>
<style scoped lang="sass">
.practice-question
    user-select: none
</style>
