<template>
    <div class="practice-quiz-preview">
        <div v-if="getUser() === null">
            <default-title inter :size="28" class="text-center">
                {{ t("create-course.access_denied") }}
            </default-title>
            <button @click="login" class="mx-auto block mt-35">
                <span class="course__content_actions__item course__content_actions__item_green">
                    {{ t("expert.usermenu.login") }}
                </span>
            </button>
        </div>
        <div v-else class="practice-quiz-preview-content">
            <flex-container align="center" id="practice-main">
                <PracticeTimerIcon class="mr-7" v-if="quiz.options.timer_duration || quizIncludesQuestionWithTimer()" />
                <practice-title>
                    {{ quiz.name }}
                </practice-title>
            </flex-container>
            <practice-blue-alert v-if="quiz.estimate.is_on && quiz.estimate.is_show" class="mt-10">
                {{ t("course.detail.quiz_scores") }}:
                <strong
                    ><template v-if="quiz.common_scores_min">{{ quiz.common_scores_min }} -</template>
                    {{ quiz.common_scores_max }}</strong
                >
            </practice-blue-alert>

            <practice-blue-alert
                :key="quiz.attempt.id"
                style="margin-top: 10px"
                v-if="quiz.versioning_type_id === 3 && quiz.attempts.length > 1"
            >
                {{ t("course.detail.version_attempt") }}:
                {{ format(quiz.attempt.finished_at) }}
                <DefaultDropdown
                    ref="dropdown"
                    v-if="quiz.attempts.length > 1"
                    :cases="getAttemptsCases()"
                    scroll-active
                    @update="setAttempt(quiz.attempts.find(a => $event.id === a.id))"
                    :active-case="{ id: quiz.attempt.id, name: quiz.attempt.finished_at }"
                    class="inline-block versions-dropdown"
                >
                    <template #handler>
                        <a
                            style="text-underline-offset: 3px"
                            class="ml-10 text-blue cursor-pointer underline"
                            @click="show()"
                            >+ {{ t("course.detail.once_versions").replaceAll("$", quiz.attempts.length - 1) }}
                        </a>
                    </template>
                </DefaultDropdown>
            </practice-blue-alert>

            <!-- QUIZ PREV COMMENTS -->
            <PracticeQuizPrevComments :quiz="quiz" v-if="quiz.versioning_type_id === 2" />

            <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 6" class="mt-10 yellow">
                {{ t("course.practice.quiz_1") }}
            </practice-blue-alert>

            <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 5" class="mt-10">
                {{ t("course.detail.in_process") }}
            </practice-blue-alert>

            <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 4" class="mt-10 yellow">
                {{ t("course.detail.last_attempt_status_changed") }}

                <practice-comment
                    v-if="quiz.attempt.comment || (quiz.attempt.comment_files && quiz.attempt.comment_files.length)"
                    :attempt="quiz.attempt"
                ></practice-comment>
            </practice-blue-alert>

            <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 1" class="mt-10 green">
                {{ quiz.passed_text ? quiz.passed_text : t("course.practice.quiz_2") }}
                <br/>
                <template v-if="quiz.estimate.is_on && quiz.estimate.is_show"
                    >{{ t("course.practice.points_received") }} <strong>{{ quiz.attempt.scores }}</strong></template
                >
                <practice-comment
                    v-if="quiz.attempt.comment || (quiz.attempt.comment_files && quiz.attempt.comment_files.length)"
                    :attempt="quiz.attempt"
                ></practice-comment>
            </practice-blue-alert>

            <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 2" class="mt-10 red">
                {{ t("course.practice.quiz_3")
                }}<template v-if="quiz.estimate.is_on && quiz.estimate.is_show">
                    {{ t("course.practice.current_score") }} <strong>{{ quiz.attempt.scores }}</strong></template
                >
                <practice-comment
                    v-if="quiz.attempt.comment || (quiz.attempt.comment_files && quiz.attempt.comment_files.length)"
                    :attempt="quiz.attempt"
                >
                </practice-comment>
            </practice-blue-alert>

            <practice-blue-alert v-if="needRework" class="mt-10 yellow">
                {{ t("course.need_rework") }}
                <practice-comment
                    v-if="quiz.attempt.comment || (quiz.attempt.comment_files && quiz.attempt.comment_files.length)"
                    :attempt="quiz.attempt"
                >
                </practice-comment>
            </practice-blue-alert>

            <practice-red-alert
                v-if="(quiz.options.timer_duration || quizIncludesQuestionWithTimer()) && !quiz.attempt"
                class="mt-10"
            >
                <template v-if="!quizIncludesQuestionWithTimer() && quiz.options.timer_duration">
                    <strong>{{ t("course.practice.attension") }}</strong>
                    <span v-html="t('course.practice.quiz_4')"></span>
                    <br />
                    {{ t("course.quiz_alloted_time") }}:
                    <strong class="inline">
                        <PracticeTimer
                            track-by="duration_in_seconds"
                            :attempt="quiz.options.timer_duration"
                            only-display
                        />
                    </strong>
                </template>
                <template v-if="quizIncludesQuestionWithTimer() && !quiz.options.timer_duration">
                    <strong>{{ t("course.practice.attension") }}</strong>
                    {{ t("course.practice.quiz_5") }}
                </template>
                <template v-if="quizIncludesQuestionWithTimer() && quiz.options.timer_duration">
                    <strong>{{ t("course.practice.attension") }}</strong>
                    {{ t("course.practice.quiz_6") }}
                    <ul>
                        <li>
                            {{ t("course.practice.quiz_7") }}
                        </li>
                        <li>
                            {{ t("course.practice.quiz_8") }}
                        </li>
                    </ul>
                </template>
            </practice-red-alert>

            <flex-container
                v-show="mount"
                :direction="$screenWidth > 640 ? 'row' : 'column'"
                :align="$screenWidth > 640 ? 'center' : 'flex-start'"
            >
                <button
                    v-if="showStartButton"
                    @click="startAttempt()"
                    :class="{ 'practice-quiz-preview__btn_load': loading, loading }"
                    class="practice-quiz-preview__btn mt-10"
                >
                    <span>
                        <template v-if="quiz.attempt && quiz.attempt.status_id === 3">
                            {{ t("course.practice.finalize") }}
                        </template>
                        <template v-else-if="quiz.attempt && quiz.attempt.status_id !== 5">
                            {{ t("course.practice.replay") }}
                        </template>
                        <template v-else>
                            <template v-if="quizIncludesQuestionWithTimer() || quiz.options.timer_duration">
                                {{ t("course.practice.understand") }}
                            </template>
                            {{ t("course.practice.start") }}
                        </template>
                    </span>
                </button>
                <button
                    v-if="showResultButton"
                    @click="resultDisplayed = !resultDisplayed"
                    class="practice-quiz-preview__btn practice-quiz-preview__btn_result mt-10"
                    :class="{ 'practice-quiz-preview__btn_result_only': !showStartButton || $screenWidth <= 640 }"
                >
                    <span v-if="!resultDisplayed">
                        {{ t("course.practice.display_result") }}
                    </span>
                    <span v-else>
                        {{ t("course.practice.hide_result") }}
                    </span>
                </button>
            </flex-container>

            <red-alert style="max-width: 700px" v-if="showResultAlert" class="mt-10 mb-25">
                <strong>
                    {{ t("expert.attention") }}
                </strong>
                <div v-html="quiz.attempt_limit_text ? quiz.attempt_limit_text : t('course.practice.cant_retry')"></div>
            </red-alert>

            <AttemptResult
                class="mt-20"
                v-if="showResultButton && resultDisplayed && !didUpdate"
                :lesson="lesson"
                :quiz="quiz"
                :course-id="lesson.course_id"
                :attempt-id="quiz.attempt.id"
            />

            <!-- HOT FIX -->
            <PracticeOnlyTextGraphicAnswersBlock
                :unit-id="unitId"
                :lesson="lesson"
                :quiz="quiz"
                v-if="
                    quiz.attempt &&
                    !quiz.result_attempt.is_show &&
                    quiz.attempt.status_id !== 5 &&
                    quiz.questions &&
                    quiz.type !== 'text_graphic_answer' &&
                    quiz.questions.find(q => q.type.slug === 'text_graphic_answer')
                "
            />
        </div>
    </div>
</template>

<script>
import RedAlert from "@components/Alerts/RedAlert"
import FlexContainer from "@components/Containers/FlexContainer"
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"
import AttemptResult from "@components/Practice/AttemptResult"
import PracticeTimer from "@components/Practice/common/timer/PracticeTimer.vue"
import PracticeTimerIcon from "@components/Practice/common/timer/PracticeTimerIcon"
import PracticeBlueAlert from "@components/Practice/common/alerts/PracticeBlueAlert"
import PracticeRedAlert from "@components/Practice/common/alerts/PracticeRedAlert"
import PracticeRepository from "@components/Practice/shared/repository/practice-repository"
import PracticeTitle from "@components/Practice/common/typography/PracticeTitle"
import PracticeComment from "@components/Practice/common/typography/PracticeComment"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import { DEFAULT_AUTH_KEY } from "@public-modal/shared/keys/default-auth-key"
import moment from "moment-timezone"
import PracticeOnlyTextGraphicAnswersBlock from "./PracticeOnlyTextGraphicAnswersBlock"
import { EventBus } from "~events"
import PracticeQuizPrevComments from "./PracticeQuizPrevComments.vue"

export default {
    props: {
        unitId: {
            type: Number,
            default: 0
        },
        quiz: {
            type: Object,
            default: null
        },
        lesson: {
            type: Object,
            default: null
        }
    },
    name: "PracticeQuizPreview",
    components: {
        PracticeQuizPrevComments,
        DefaultDropdown,
        DefaultTitle,
        PracticeTimer,
        RedAlert,
        AttemptResult,
        PracticeOnlyTextGraphicAnswersBlock,
        PracticeComment,
        PracticeTitle,
        PracticeRedAlert,
        PracticeBlueAlert,
        PracticeTimerIcon,
        FlexContainer
    },
    data() {
        return {
            loading: false,
            resultDisplayed: false,
            mount: false,
            didUpdate: false
        }
    },
    async created() {
        window.onbeforeunload = null

        if (!this.getUser()) {
            return
        }
        this.resultDisplayed = location.href.includes("quizzes")

        if (this.quiz.type === "text_graphic_answer") {
            return
        }

        this.quiz.can_start_attempt = false

        EventBus.$emit("practice:update-status", { id: this.quiz.id, status: this.quiz.status_id })

        const { data: quiz } = await PracticeRepository.quiz({
            quiz_id: this.quiz.id,
            lesson_id: this.lesson.id,
            unit_id: this.unitId
        })

        const { can_start_attempt, is_run_out_of_attempts, attempts } = quiz

        this.quiz.can_start_attempt = can_start_attempt
        this.quiz.attempts = attempts
        this.quiz.is_run_out_of_attempts = is_run_out_of_attempts

        const url = new URL(location)
        const attemptId = url.searchParams.get("quiz_attempt_id")

        if (attemptId) {
            const attempt = quiz.attempts.find(attempt => attempt.id == attemptId)

            if (attempt) {
                this.setAttempt(attempt)
                this.resultDisplayed = true
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.mount = true
        }, 100)
    },
    methods: {
        show() {
            this.$refs.dropdown.show()
        },
        setAttempt($event) {
            this.$set(this.quiz, "attempt", {
                ...$event,
                results: null
            })
            this.forceUpdate()
        },
        forceUpdate() {
            this.didUpdate = true
            this.$nextTick(() => {
                this.didUpdate = false
            })
        },
        format(date) {
            return moment(date).format("DD MMMM, YYYY HH:mm")
        },
        getAttemptsCases() {
            return [...this.quiz.attempts].map(a => ({
                id: a.id,
                name: a.finished_at ? moment(a.finished_at).format("DD MMMM, YYYY HH:mm") : "-"
            }))
        },
        login() {
            EventBus.$emit(`${DEFAULT_AUTH_KEY}_OPEN`)
        },
        getUser() {
            return window.user
        },
        quizIncludesQuestionWithTimer() {
            if (!this.quiz) {
                return false
            }
            return !!this.quiz.questions.find(q => q.timer)
        },
        async startAttempt() {
            this.loading = true
            try {
                const payload = {
                    lesson_id: this.lesson.id,
                    quiz_id: this.quiz.id,
                    unit_id: this.unitId
                }
                const { data: attempt } = await PracticeRepository.attempt(payload)
                if (attempt) {
                    const { data: questions } = await PracticeRepository.questions({
                        ...payload,
                        attempt_id: attempt.id
                    })

                    this.$set(this.quiz, "questions", questions)
                    this.$set(this.quiz, "attempt", attempt)

                    // Set finalize key to true in quiz entity and show answer block
                    if (this.quiz.attempt && this.quiz.attempt.status_id === 3 && !this.quiz.finalize) {
                        this.$set(this.quiz, "finalize", true)
                    }

                    EventBus.$emit("practice:update-status", { id: this.quiz.id, status: this.quiz.status_id })

                    this.$emit("rerender")
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    computed: {
        showResultButton() {
            return (
                this.quiz.attempt &&
                this.quiz.attempt.status_id !== 5 &&
                this.quiz.type !== "text_graphic_answer" &&
                this.quiz.result_attempt.is_show
            )
        },
        showResultAlert() {
            if (!this.quiz) {
                return false
            }
            return this.quiz.is_run_out_of_attempts && !this.loading
        },
        showStartButton() {
            if (this.quiz.can_start_attempt) {
                if (this.quiz.type !== "text_graphic_answer") {
                    return true
                } else {
                    if (this.quizIncludesQuestionWithTimer() || this.quiz.options.timer_duration) {
                        return !this.quiz.attempt
                    }
                }
            }
            return false
        },
        needRework() {
            let attempt = this.quiz.attempt
            let previousAttempt = this.quiz.attempt?.previous_attempt
            return (
                (attempt && attempt.status_id === 3) ||
                (attempt && attempt.status_id === null && previousAttempt && previousAttempt.status_id === 3)
            )
        }
    }
}
</script>

<style scoped lang="sass">
.versions-dropdown
    &::v-deep
        .default-dropdown__modal
            top: 30px
            min-width: 190px
.practice-quiz-preview
    .pracricle-timer-icon
        margin-right: 8px
    &__btn
        height: 45px
        display: flex
        align-items: center
        width: 250px
        justify-content: center
        background: #30C58F
        border-radius: 7px
        font-size: 15px
        color: #fff
        line-height: 22px
        font-weight: 500
        transition: .2s
        font-family: 'Ubuntu', serif

        &:hover
            background: #28BA85
        &_load
            opacity: .8
            span
                display: none
            pointer-events: none
            &::after
                width: 26px
                height: 26px
                opacity: 1
                margin-top: -13px
                margin-left: -13px
                background: url("@images/portal/loader-icon.svg")
        &_result
            background: transparent
            color: #3965FF
            text-decoration: underline
            text-underline-offset: 2px
            &_only
                width: max-content
                padding: 0 10px
            &:hover
                background: transparent
</style>
