<template>
    <span class="practice-timer">
        <template v-if="!didUpdate">
            {{ getValue() }}
        </template>
    </span>
</template>

<script>
import TimerMixin from "@components/Practice/common/timer/_timer"
import TimeoutWorker from "@workers/timeout-worker?worker"
import { v1 as uuid } from "uuid"

export default {
    props: {
        attempt: {
            type: Object,
            default: () => {}
        },
        trackBy: {
            type: String,
            default: "left_time_in_seconds"
        },
        onlyDisplay: {
            type: Boolean,
            default: false
        }
    },
    name: "PracticeTimer",
    data() {
        return {
            interval: null,
            didUpdate: false,
            worker: new TimeoutWorker()
        }
    },
    mixins: [TimerMixin],
    created() {
        if (this.onlyDisplay) {
            return false
        }

        let seconds = this.attempt[this.trackBy]

        const task = () => {
            seconds -= 1

            if (seconds >= 0) {
                this.attempt[this.trackBy] = seconds

                this.$forceUpdate()

                this.didUpdate = true
                this.$nextTick(() => {
                    this.didUpdate = false
                })
            }
        }

        if (import.meta.env.MODE && import.meta.env.MODE === "development") {
            this.interval = setInterval(task, 1000)
        } else {
            const id = uuid()

            this.worker.postMessage({
                name: "setInterval",
                id,
                time: 1000
            })
            this.worker.onmessage = task
            this.interval = id
        }
    },
    methods: {
        getValue() {
            const value = this.attempt[this.trackBy]

            const day = 86400
            const hour = 3600
            const minute = 60

            const days = Math.floor(value / day)
            const hours = Math.floor((value - days * day) / hour)
            const minutes = Math.floor((value - days * day - hours * hour) / minute)
            const seconds = value - days * day - hours * hour - minutes * minute

            if (days) {
                let timer_arr = []
                let timer_str = ``
                timer_arr.push(
                    `${this.num_decline(
                        days,
                        [this.t("expert.day_1"), this.t("expert.day_2"), this.t("expert.day_3")],
                        true
                    )}`
                )

                timer_arr.push(
                    `${this.num_decline(
                        hours,
                        [this.t("quiz.hours_3"), this.t("quiz.hours_2"), this.t("quiz.hours_1")],
                        true
                    )}`
                )

                timer_arr.push(
                    `${this.num_decline(
                        minutes,
                        [this.t("quiz.minutes_3"), this.t("quiz.minutes_2"), this.t("quiz.minutes_1")],
                        true
                    )}`
                )

                timer_str = timer_arr.join(" ")

                if (timer_str) {
                    return `${timer_str}`.toLowerCase()
                }
            } else {
                let timer_str = ""
                const timer_arr = [hours, minutes, seconds].map(val => {
                    if (val < 10) {
                        return `0${val}`
                    }
                    return val
                })

                timer_str = timer_arr.join(":")

                if (timer_str) {
                    return `${timer_str}`.toLowerCase()
                }
            }

            return ``
        }
    },
    destroyed() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }
}
</script>

<style scoped lang="sass">
.practice-timer
    color: #DD4141
    font-weight: 600
    font-size: 16px
</style>
