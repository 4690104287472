<template>
    <div :style="questionStyle()" class="practice-question">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <PracticeQuestionDescription class="pt-10 pb-15" :description="question.description" v-if="false" />
        <div v-else class="mb-15"></div>

        <FilesNewView v-if="question.files && question.files.length" :files="question.files" />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers"
            :class="{ 'practice-question__answers_disabled': disabledByTimer() }"
        >
            <draggable
                :class="{ 'pointer-events-none': readonly || !interactive }"
                v-model="question.answers"
                @end="$emit('callout')"
                v-bind="dragOptions"
            >
                <transition-group name="flip-list">
                    <div
                        class="practice-question__answer practice-question__answer_draggable"
                        v-for="answer in question.answers"
                        :key="answer.id"
                        :class="{
                            correct: isAnswerCorrect(answer),
                            incorrect: !isAnswerCorrect(answer),
                            colored:
                                isAnswerCorrect(answer) !== undefined &&
                                !interactive &&
                                typeShow() !== 'simple_answer' &&
                                typeShow() !== 'only_correct_or_not_question'
                        }"
                    >
                        <MoveHandlerIcon class="handler" />
                        <div>
                            <img
                                :style="getImageStyle(answer)"
                                v-if="answer.image"
                                :src="answer.image.url || answer.image"
                                alt="image"
                                class="mt-10"
                            />
                            <PracticeQuestionDescription :description="answer.value || answer.text" />
                        </div>
                    </div>
                </transition-group>
            </draggable>
        </div>

        <div class="practice-question__answers" v-if="!interactive && typeShow() === 'full_information' && !expertView">
            <default-title class="mt-20 mb-20" :size="16" :weight="500" :line-height="21">
                {{ t("quiz.correct_answer") }}
            </default-title>
            <draggable :class="{ 'pointer-events-none': !interactive }" v-model="question.answers" v-bind="dragOptions">
                <div
                    class="practice-question__answer practice-question__answer_draggable"
                    v-for="(answer, index) in getCorrectAnswers()"
                    :key="index"
                    :class="{
                        correct: true,
                        colored: true
                    }"
                >
                    <MoveHandlerIcon class="handler" />
                    <div>
                        <img
                            :style="getImageStyle(answer)"
                            v-if="answer.image"
                            :src="answer.image.url || answer.image"
                            alt="image"
                            class="mt-10"
                        />
                        <PracticeQuestionDescription :description="answer.value || answer.text" />
                    </div>
                </div>
            </draggable>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer
            :allowed="true"
            :question="question"
            :quiz="quiz"
            v-if="interactive"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import PracticeCorrectStatusAlert from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import DefaultTitle from "../../Typography/DefaultTitle"
import PracticeMixin from "./_mixin"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import FilesNewView from "../../Files/FilesNewView"
import MoveHandlerIcon from "@icons/MoveHandlerIcon"
import draggable from "vuedraggable"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"

export default {
    mixins: [PracticeMixin],
    components: {
        PracticeCorrectStatusAlert,
        DefaultTitle,
        PracticeActionsContainer,
        PracticeQuestionInfo,
        MoveHandlerIcon,
        FilesNewView,
        PracticeQuestionDefaultOptions,
        draggable
    },
    name: "PracticeSortingAnswer",
    data() {
        return {
            dragOptions: {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "practice-question__answer_draggable_selected",
                forceFallback: navigator.userAgent.toLowerCase().indexOf("firefox") > -1
            }
        }
    },
    methods: {
        getCorrectAnswers() {
            return this.question.answers.map((answer, index) => {
                return {
                    ...answer,
                    text: this.question.answers.find(answer => answer.order === index + 1)?.text || ""
                }
            })
        },
        isAnswerCorrect(answer) {
            if (this.interactive) {
                return false
            }

            return this.isAnswered(answer)?.is_correct
        }
    }
}
</script>
<style lang="sass">
.practice-question__answer
    &_draggable
        cursor: grab
        min-height: 55px
        display: flex
        align-items: flex-start
        padding-top: 16px
        justify-content: flex-start
        box-shadow: 0px 2px 5px rgba(128, 158, 191, 0.25)
        border-radius: 5px
        padding-left: 15px
        background: #FFF
        transition: .2s
        margin-bottom: 10px !important
        &_selected
            background-color: #F2F7FF
        .handler
            margin-right: 20px
            min-width: 24px
        &.colored.correct
            border: 1px solid #15A06E
            background: #F9FEFC
        &.colored.incorrect
            border: 1px solid #DD4141
            background: #FFF6F6
</style>
